import {iamService} from "@/common/services/request";
import {withBase} from "ufo";
import {CLIENT_ID, iamURL, PLAYLINK_STORAGE_KEY} from "@/common/constants";
import store from "@/store";
import {appSessionStorage} from "@/common/utils/app-storage";

export async function APIUserIdentity() {
  const { data: response } = await iamService('/v2/accounts/account-credentials', {
    method: 'GET'
  })
  return response
}

export function APIConnectSocial(name) {
  return withBase(`/v2/social-oauth/${name}/authorize`, iamURL)
}

export async function APILinkSocial(socialChannel, state) {
  const { data: response } = await iamService('/v2/me/connect/social-account', {
    method: 'POST',
    data: {
      socialChannel,
      state
    }
  })
  return response
}

export async function APIMergeAccount(mergeRequestId){
  const { data: response } = await iamService('/v2/me/merge-account', {
    method: 'POST',
    data: {
      mergeRequestId
    }
  })
  return response
}

export async function APIAuthChallenge(data) {
  const { data: response } = await iamService('/v2/oauth2/challenge', {
    method: 'POST',
    data: {
      ...data,
      type: 'auth',
      encodedAddress: data.publicAddress,
      addressBase: data.addressBase,
      connectionKey: data.connectionKey,
      algorithm: data.algorithm
    }
  })
  return response
}

export async function APIAuthVerify(data) {
  const { data: { data: { accessToken } } } = await iamService('/v2/oauth2/token', {
    method: 'POST',
    data: {
      ...data,
      encodedAddress: data.publicAddress,
      encodedPublicKey: data.publicKey,
      encodedSignature: data.signature,
      grant_type: 'web3_sign',
      client_id: CLIENT_ID
    }
  })
  await store.commit('SET_ACCESS_TOKEN', accessToken)
  appSessionStorage.setWithExpiry(PLAYLINK_STORAGE_KEY.ACCESS_TOKEN, {
    accessToken,
    walletAddress: data.publicAddress
  }, 45 * 60 * 1000)
}

export async function APILoginEmail({ email, password }) {
  const { data: { data: { accessToken } } } = await iamService('/v2/oauth2/token', {
    method: 'POST',
    data: {
      email,
      password,
      grant_type: 'password',
      client_id: CLIENT_ID
    }
  })
  await store.commit('SET_ACCESS_TOKEN', accessToken)
  appSessionStorage.setWithExpiry(PLAYLINK_STORAGE_KEY.ACCESS_TOKEN, { accessToken }, 45 * 60 * 1000)
}

export async function APIRequestOTP({ email }){
  const response = await iamService('/v2/oauth2/otp', {
    methods: 'GET',
    params: {
      email,
      clientId: CLIENT_ID
    }
  })
  return response
}

export async function APILoginOTP({ email, otp }) {
  const { data: { data: { accessToken } } } = await iamService('/v2/oauth2/token', {
    method: 'POST',
    data: {
      email,
      otp,
      grant_type: 'otp',
      client_id: CLIENT_ID
    }
  })
  await store.commit('SET_ACCESS_TOKEN', accessToken)
  appSessionStorage.setWithExpiry(PLAYLINK_STORAGE_KEY.ACCESS_TOKEN, { accessToken }, 45 * 60 * 1000)
}

export async function APIAuthVerifyWithoutConnect(data) {
  await iamService('/v2/oauth2/request-web3-auth-no-connect', {
    method: 'POST',
    data
  })
}

export async function APILinkChallenge(data) {
  const { data: { data: response } } = await iamService('/v2/me/connect/wallet/challenge', {
    method: 'POST',
    data: {
      encodedAddress: data.publicAddress,
      addressBase: data.addressBase,
      connectionKey: data.connectionKey,
      algorithm: data.algorithm
    }
  })
  return response
}

export async function APILinkVerify(data) {
  const { data: { data: response } } = await iamService('/v2/me/connect/wallet/verify', {
    method: 'POST',
    data: {
      encodedAddress: data.publicAddress,
      addressBase: data.addressBase,
      signatureBase: data.signatureBase,
      encodedPublicKey: data.publicKey,
      publicKeyBase: data.publicKeyBase,
      algorithm: data.algorithm,
      networkCluster: data.networkCluster,
      extraData: {},
      ...(typeof data.signature === 'string' ? { encodedSignature: data.signature } : data.signature)
    }
  })
  return response
}

export async function APIFulfillWeb3AuthWithoutConnect({ connectionKey }) {
  const { data: { access_token, walletAddress } } = await iamService('/v2/oauth2/fulfill-web3-auth-no-connect', {
    method: 'POST',
    data: {
      connectionKey,
      clientId: CLIENT_ID
    }
  })
  await store.commit('SET_ACCESS_TOKEN', access_token)
  appSessionStorage.setWithExpiry(PLAYLINK_STORAGE_KEY.ACCESS_TOKEN, {
    accessToken: access_token,
    walletAddress
  }, 45 * 60 * 1000)
  return {
    address: walletAddress
  }
}

export async function APILinkVerifyWithoutConnect(data) {
  await iamService('/v2/me/connect/wallet/request-verify-no-connect', {
    method: 'POST',
    data
  })
}

export async function APILinkFulfillVerify(data) {
  const { data: { data: response } } = await iamService('/v2/me/connect/wallet/fulfill-verify-no-connect', {
    method: 'POST',
    data
  })
  return response
}
