export * from "./errors";
export * from "./configs";
export * from "./web3";
export * from "./constants";

export const baseURL = process.env.VUE_APP_BASE_API || 'https://demo.0m.gg/playlink/api'
export const TERMS_OF_USE_URL = process.env.VUE_APP_TERMS_OF_USE || 'https://cdn.superfine.org/legal/TermsofUse.html'
export const PRIVACY_POLICY_URL = process.env.VUE_APP_PRIVACY_POLICY || 'https://cdn.superfine.org/legal/PrivacyPolicy.html'

export const GOOGLE_CAPTCHA_KEY = process.env.VUE_APP_GOOGLE_CAPTCHA_KEY || '6LcpBIUjAAAAANEBBcDPR9lRx16bM3nh27-eBGQM'

export const FLOWCHAIN_FUNGIBLE_TOKEN = process.env.VUE_APP_FLOWCHAIN_FUNGIBLE_TOKEN
export const FLOWCHAIN_NON_FUNGIBLE_TOKEN = process.env.VUE_APP_FLOWCHAIN_NON_FUNGIBLE_TOKEN
export const RONIN_CHAIN = process.env.VUE_APP_RONIN_CHAIN
export const IMMUTABLEX_CHAIN = process.env.VUE_APP_IMMUTABLEX_CHAIN
export const CHALLENGE_TEMPLATE = process.env.VUE_APP_CHALLENGE_TEMPLATE + "${connectionKey}"
export const INFURA_ID = process.env.VUE_APP_INFURA_ID || "88eb7c595590415da3abf2945b953a1a"
export const WALLETCONNECT_PROJECT_ID = process.env.VUE_APP_WALLETCONNECT_PROJECT_ID

export const iamURL = process.env.VUE_APP_IAM_API || 'https://demo.0m.gg/iam/api'

export const CLIENT_ID = process.env.VUE_APP_IAM_CLIENT || '01GWPFGCX6BNN3KWJXGKGSRS9S'
