// WalletConnect
export const WALLET_CONNECT_KEY = 'walletconnect';

// Ronin
export const RONIN_SIGNING = 'ronin';
export const RONIN_SSO_SIGNING = 'ronin-sso';
export const SIGNING_TYPE = 'signingType';
export const SIG_REQUEST_TIMESTAMP = 'sigRequestTimestamp';

// FlowChain
export const SIGNING_ALGORITHM = 'signingAlgorithm';
export const HASHING_ALGORITHM = 'hashingAlgorithm';

export const PLAYLINK_STORAGE_KEY = {
  WALLET: 'playlink-wallet',
  SID: 'playlink-sId',
  CAMPAIGN: 'pl-campaign',
  TWITTER: 'pl-connected-twitter',
  DISCORD: 'pl-connected-discord',
  LOGIN: 'login-timestamp',
  UID: 'playlink-uid',
  SECRET: 'pl-correct-secret',
  FACEBOOK: 'pl-connected-facebook',
  NOTIFICATION: 'pl-notification-setup',
  BLOCKCHAIN: 'pl-blockchain',
  CONNECTION_KEY: 'pl-connection-key',
  TELEGRAM: 'pl-connected-telegram',
  TWITCH: 'pl-connected-twitch',
  TIKTOK: 'pl-connected-tiktok',
  LINKS: 'pl-visited-links',
  ACCESS_TOKEN: 'pl-access-token',
  SOCIAL_CHANNEL: 'pl-social-channel',
  IDENTITY: 'pl-identity',
  YOUTUBE: 'pl-connected-youtube',
  ANSWER: 'pl-answer'
};
