import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/preview/:campaignSlug',
    name: 'Preview',
    component: () => import('@/views/CampaignView-v2'),
    props: { preview: true }
  },
  {
    path: '/v1/:campaignSlug',
    name: 'Campaign-v2',
    component: () => import('@/views/CampaignView')
  },
  {
    path: '/:campaignSlug',
    name: 'Campaign',
    component: () => import('@/views/CampaignView-v2')
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('@/views/NotFound')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
