/**
 * App Storage class
 * @description This will be responsible for storing data into the application.
 * Commonly, people use LocalStorage or SessionStorage. This is just a wrapper over them
 * because to restrict the usage of Global window storage throughtout the application
 * Default, this is just using the LocalStorage
 */
export class AppStorage {
  constructor(storage, type) {
    this.storage = storage || window.localStorage
    this.type = type || 'local'

    /** Is storage is supported or not */
    if (!this.isSupported()) {
      throw new Error('Storage is not supported by browser!')
    }
  }

  setItem(key, value) {
    if (this.type === 'local') {
      this.storage.setItem(key, JSON.stringify(value))
    } else {
      this.storage.set(key, value)
    }
  }

  getItem(key) {
    if (this.type === 'local') {
      return JSON.parse(this.storage.getItem(key))
    } else {
      return this.storage.get(key)
    }
  }

  removeItem(key) {
    if (this.type === 'local') {
      this.storage.removeItem(key)
    } else {
      this.storage.remove(key)
    }
  }

  setWithExpiry(key, value, ttl) {
    const now = new Date()

    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    }

    this.setItem(key, item)
  }

  getWithExpiry(key) {
    const itemStr = this.getItem(key)
    if (!itemStr) {
      return null
    }

    let item = itemStr
    try {
      if (typeof itemStr === 'string')
        item = JSON.parse(itemStr)

      const now = new Date()

      if (now.getTime() > item.expiry) {
        this.removeItem(key)
        return null
      }
      return item.value
    } catch {
      return itemStr
    }
  }

  removeBulkItems(keys) {
    for (const i in keys) {
      this.removeItem(keys[i])
    }
  }

  clear() {
    this.storage.clear()
  }

  /**
   * @description Check for storage support
   * @returns {boolean} supported
   * */
  isSupported() {
    let supported = true

    if (!this.storage) {
      supported = false
    }

    return supported
  }
}

/**
 * Creating the instance of storage. Default will be localStorage
 * but if you want to create instance for session storage then pass window.sessionStorage as parameter
 */
const appLocalStorage = new AppStorage()
const appSessionStorage = new AppStorage(window.sessionStorage)
export { appLocalStorage, appSessionStorage }
