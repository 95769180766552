import Bowser from 'bowser'
import {FLOWCHAIN_FUNGIBLE_TOKEN, FLOWCHAIN_NON_FUNGIBLE_TOKEN, NETWORK_CLUSTER} from "@/common/constants";
import {ethers} from "ethers";
import ERC20 from "@/assets/json/eth-erc20.json";
import {Connection, PublicKey} from "@solana/web3.js";
import ERC721 from "@/assets/json/eth-erc721.json";
import {getAccount, getAssociatedTokenAddress, getMint} from "@solana/spl-token";
import {JsonRpcProvider} from "@mysten/sui.js";
import {ImmutableXClient} from "@imtbl/imx-sdk";
import * as fcl from "@onflow/fcl";

export function isDesktop() {
  const browser = Bowser.getParser(window.navigator.userAgent)
  return browser.getPlatformType(true) === 'desktop'
}

export function arrayBufferToBase64(buffer) {
  let binary = ''
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}

export function validateEmail(email) {
  return String(email)
      .toLowerCase()
      .match(/^([^@]+)@([\w.-]+\.[\w-]{2,})$/)
}

export function isJSONSerializable(value) {
  if (value === undefined) {
    return false
  }
  const t = typeof value
  if (t === "string" || t === "number" || t === "boolean" || t === null) {
    return true
  }
  if (t !== "object") {
    return false // bigint, function, symbol, undefined
  }
  if (Array.isArray(value)) {
    return true
  }
  return (value.constructor && value.constructor.name === "Object") ||
      typeof value.toJSON === "function"
}

const payloadMethods = new Set(Object.freeze(["PATCH", "POST", "PUT", "DELETE"]))

export function isPayloadMethod(method = "GET") {
  return payloadMethods.has(method.toUpperCase())
}

export async function getBalance(address, networkCluster, rpc, decimal) {
  try {
    if (networkCluster === NETWORK_CLUSTER.SOLANA) {
      let connection = new Connection(rpc, "confirmed");
      return parseFloat(ethers.utils.formatUnits(
        await connection.getBalance(new PublicKey(address)), decimal
      ));
    } else if (networkCluster === NETWORK_CLUSTER.SUI) {
      let jsonRpcProvider = new JsonRpcProvider(new Connection({ fullnode: rpc}));
      let balance = await jsonRpcProvider.getBalance({ owner: address });
      return parseFloat(ethers.utils.formatUnits(balance.totalBalance, decimal));
    } else if (networkCluster === NETWORK_CLUSTER.IMMUTABLEX) {
      let client = await ImmutableXClient.build({ publicApiUrl: rpc });
      let balance = await client.getBalances({ user: address });
      return parseFloat(ethers.utils.formatEther(balance.imx));
    } else if (networkCluster === NETWORK_CLUSTER.FLOWCHAIN) {
      let account = await fcl.account(address);
      return parseFloat(ethers.utils.formatUnits(account?.balance, decimal));
    } else {
      const provider = new ethers.providers.JsonRpcProvider(rpc);
      const balance = await provider.getBalance(address);
      return parseFloat(ethers.utils.formatEther(balance));
    }
  } catch (e) {
    console.error(e)
    return 0
  }
}
export async function getFTBalance(token, address, networkCluster, rpc) {
  try {
    if (networkCluster === NETWORK_CLUSTER.SOLANA) {
      let connection = new Connection(rpc, "confirmed");
      let tokenAccount = await getAssociatedTokenAddress(new PublicKey(token), new PublicKey(address));
      let tokenAccountInfo = await getAccount(connection, tokenAccount);
      let mintInfo = await getMint(connection, new PublicKey(token));
      return parseFloat(ethers.utils.formatUnits(
        tokenAccountInfo?.amount?.toString(),
        mintInfo?.decimals
      ));
    } else if (networkCluster === NETWORK_CLUSTER.SUI) {
      let jsonRpcProvider = new JsonRpcProvider(new Connection({ fullnode: rpc}));
      let balance = await jsonRpcProvider.getBalance({ owner: address, coinType: token });
      let metadata = await jsonRpcProvider.getCoinMetadata({ coinType: token })
      return parseFloat(ethers.utils.formatUnits(balance.totalBalance, metadata.decimals));
    } else if (networkCluster === NETWORK_CLUSTER.IMMUTABLEX) {
      let client = await ImmutableXClient.build({ publicApiUrl: rpc });
      let tokenInfo = await client.getToken({ tokenAddress: token });
      let balanceInfo = await client.getBalance({
        user: address,
        tokenAddress: token
      });
      return parseFloat(ethers.utils.formatUnits(balanceInfo.balance, tokenInfo.decimals));
    } else if (networkCluster === NETWORK_CLUSTER.FLOWCHAIN) {
      let tokenInfo = token?.split(".");
      if (tokenInfo.length < 3)
        return 0;
      let accountAddress = tokenInfo[1];
      if (!accountAddress?.startsWith("0x"))
        accountAddress = "0x" + accountAddress;
      let tokenName = tokenInfo[2];
      const balance = await fcl.query({
        cadence: `
          import FungibleToken from ${FLOWCHAIN_FUNGIBLE_TOKEN}
          import ${tokenName} from ${accountAddress}

          pub fun main(): UFix64 {
            let account = getAccount(${address})
            let vaultRef = account
              .getCapability(${tokenName}.VaultPublicPath)
              .borrow<&${tokenName}.Vault{FungibleToken.Balance}>()
              ?? panic("Could not borrow Balance reference to the Vault")
            return vaultRef.balance
          }
        `
      });
      return parseFloat(balance);
    } else {
      const provider = new ethers.providers.JsonRpcProvider(rpc);
      const contract = new ethers.Contract(token, ERC20, provider);
      const balance = await contract.balanceOf(address);
      const decimals = await contract.decimals();
      return parseFloat(ethers.utils.formatUnits(balance, decimals));
    }
  } catch (e) {
    console.error(e)
    return 0
  }
}

export async function getNFTBalance(token, address, networkCluster, rpc) {
  try {
    if (networkCluster === NETWORK_CLUSTER.SOLANA) {
      let connection = new Connection(rpc, "confirmed");
      let tokenAccount = await getAssociatedTokenAddress(new PublicKey(token), new PublicKey(address));
      let tokenAccountInfo = await getAccount(connection, tokenAccount);
      return parseFloat(tokenAccountInfo?.amount?.toString());
    } else if (networkCluster === NETWORK_CLUSTER.SUI) {
      let jsonRpcProvider = new JsonRpcProvider(new Connection({ fullnode: this.blockchain?.rpcUrls[0] }));
      let objects = await jsonRpcProvider.getOwnedObjects({
        owner: address,
        filter: { StructType: token }
      })
      return objects.data.length;
    } else if (networkCluster === NETWORK_CLUSTER.IMMUTABLEX) {
      let client = await ImmutableXClient.build({ publicApiUrl: rpc });
      let assets = await client.getAssets({
        user: address,
        status: "imx",
        collection: token
      });
      return assets.result.length;
    } else if (networkCluster === NETWORK_CLUSTER.FLOWCHAIN) {
      let tokenInfo = token?.split(".");
      if (tokenInfo.length < 3)
        return 0;
      let accountAddress = tokenInfo[1];
      if (!accountAddress?.startsWith("0x"))
        accountAddress = "0x" + accountAddress;
      let tokenName = tokenInfo[2];
      const ownedNFTs = await fcl.query({
        cadence: `
          import NonFungibleToken from ${FLOWCHAIN_NON_FUNGIBLE_TOKEN}
          import ${tokenName} from ${accountAddress}

          pub fun main(): [UInt64] {
            let account = getAccount(${address})
            let collectionRef = account
              .getCapability(${tokenName}.CollectionPublicPath)
              .borrow<&${tokenName}.Collection{NonFungibleToken.CollectionPublic}>()
              ?? panic("Could not borrow CollectionPublic reference to the Collection")
            return collectionRef.getIDs()
          }
        `
      });
      return ownedNFTs?.length;
    } else {
      const provider = new ethers.providers.JsonRpcProvider(rpc);
      const contract = new ethers.Contract(token, ERC721, provider);
      const balance = await contract.balanceOf(address);
      return parseFloat(balance);
    }
  } catch (e) {
    console.error(e)
    return 0
  }
}

export function hexToBytes(hexString) {
  if (!hexString) {
    return []
  }

  hexString = hexString.replace(/^0x/, '')

  if (!hexString) {
    return []
  }

  if (hexString.length % 2 !== 0) {
    hexString = '0' + hexString
  }

  // split the string into pairs of octets
  let pairs = hexString.match(/[\dA-F]{2}/gi)

  // convert the octets to integers
  let integers = pairs.map(function (s) {
    return parseInt(s, 16)
  })

  return integers
}

export function u64ToBytes(value) {
  let result = []
  for (let i = 0; i < 8; i++) {
    result.push(value - ((value >> 8) << 8))
    value >>= 8
  }
  return result.reverse()
}
