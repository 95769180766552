import Vue from 'vue';
import Vuex from 'vuex';
import {appLocalStorage, appSessionStorage} from "@/common/utils/app-storage";
import { PLAYLINK_STORAGE_KEY } from "@/common/constants";
import {APIUserIdentity} from "@/api/iam";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    wallet: {
      address: ''
    },
    twitter: null,
    discord: null,
    facebook: null,
    telegram: null,
    twitch: null,
    tiktok: null,
    youtube: null,
    session: null,
    balance: 0,
    tokenBalance: {},
    previewMode: false,
    connectionMode: '',
    accessToken: '',
    user: null,
    blockchains: []
  },
  getters: {
    wallet: state => state.wallet,
    connectedTwitter: state => state.twitter,
    connectedDiscord: state => state.discord,
    connectedFacebook: state => state.facebook,
    connectedTelegram: state => state.telegram,
    connectedTwitch: state => state.twitch,
    connectedTiktok: state => state.tiktok,
    connectedYoutube: state => state.youtube,
    session: state => state.session,
    callbackFn: state => state.callbackFn,
    balance: state => state.balance,
    tokenBalance: state => state.tokenBalance,
    previewMode: state => state.previewMode,
    connectionMode: state => state.connectionMode,
    accessToken: state => state.accessToken,
    user: state => state.user,
    blockchains: state => state.blockchains
  },
  mutations: {
    SET_TOKEN(state, value) {
      state.token = value;
    },
    SET_WALLET(state, value) {
      state.wallet = value;
    },
    UPDATE_WALLET(state, { key, value }) {
      Object.assign(state.wallet, { [key]: value });
    },
    SET_CHALLENGE(state, value) {
      state.challenge = value;
    },
    SET_TWITTER(state, value) {
      state.twitter = value;
    },
    SET_DISCORD(state, value) {
      state.discord = value;
    },
    SET_FACEBOOK(state, value) {
      state.facebook = value;
    },
    SET_TELEGRAM(state, value) {
      state.telegram = value;
    },
    SET_TWITCH(state, value) {
      state.twitch = value;
    },
    SET_TIKTOK(state, value) {
      state.tiktok = value;
    },
    SET_YOUTUBE(state, value) {
      state.youtube = value;
    },
    SET_SESSION(state, value) {
      state.session = value;
    },
    SET_BALANCE(state, value) {
      state.balance = value;
    },
    SET_TOKEN_BALANCE(state, value) {
      const { address, balance } = value;
      state.tokenBalance[address] = balance;
    },
    SET_PREVIEW(state, value) {
      state.previewMode = value
    },
    SET_CONNECTION_MODE(state, value) {
      state.connectionMode = value
    },
    SET_ACCESS_TOKEN(state, value) {
      state.accessToken = value
    },
    SET_USER(state, value) {
      state.user = value
    },
    SET_BLOCKCHAINS(state, value) {
      state.blockchains = value
    }
  },
  actions: {
    logout({ commit }) {
      commit('SET_SESSION', null);
      commit('SET_TWITTER', '');
      commit('SET_DISCORD', '');
      commit('SET_FACEBOOK', '')
      commit('SET_TELEGRAM', '')
      commit('SET_TWITCH', '')
      commit('SET_TIKTOK', '')
      commit('SET_YOUTUBE', '')
      commit('SET_WALLET', {});
    },
    async fetchBalance({ state, commit }, { blockchain }) {
      const wallet = state.wallet;
      let balance = 0
      if (!wallet || !wallet.getBalance) {
        balance = appLocalStorage.getItem(`pl-balance-${wallet.address}-${blockchain}`) || 0
      } else {
        balance = await wallet.getBalance();
        appLocalStorage.setItem(`pl-balance-${wallet.address}-${blockchain}`, balance)
      }
      commit('SET_BALANCE', +balance);
    },
    async fetchTokenBalance({ state, commit }, { token_address, token_type, blockchain }) {
      const wallet = state.wallet;
      let balance = 0
      if (!wallet || !wallet.getFTBalance || !wallet.getNFTBalance || !wallet.getSFTBalance) {
        balance = +appLocalStorage.getItem(`pl-balance-${wallet.address}-${blockchain}-${token_address}-${token_type}`) || 0
      } else {
        let method = token_type === "ERC20" ? "getFTBalance" : "getNFTBalance"; // SFT is not supported now
        balance = +(await wallet[method](token_address));
        appLocalStorage.setItem(`pl-balance-${wallet.address}-${blockchain}-${token_address}-${token_type}`, balance)
      }
      commit('SET_TOKEN_BALANCE', {
        address: token_address,
        balance
      });
    },
    async saveSession({ commit }, data) {
      const { twitterUsername, discordUsername, facebookShortName, telegramUsername, twitchUsername, tiktokUsername, youtubeName, walletAddress } = data;
      if (twitterUsername) {
        commit('SET_TWITTER', twitterUsername);
      }
      if (discordUsername) {
        commit('SET_DISCORD', discordUsername);
      }
      if (facebookShortName) {
        commit('SET_FACEBOOK', facebookShortName);
      }
      if (telegramUsername) {
        commit('SET_TELEGRAM', telegramUsername)
      }
      if (twitchUsername) {
        commit('SET_TWITCH', twitchUsername)
      }
      if (tiktokUsername) {
        commit('SET_TIKTOK', tiktokUsername)
      }
      if (youtubeName) {
        commit('SET_YOUTUBE', youtubeName)
      }
      if (walletAddress) {
        commit('UPDATE_WALLET', {
          key: 'address',
          value: walletAddress
        });
      }
      commit('SET_SESSION', data);
    },
    clear({ state }) {
      state.wallet = {}
      state.twitter = ''
      state.discord = ''
      state.facebook = ''
      state.telegram = ''
      state.twitch = ''
      state.tiktok = ''
      state.youtube = ''
      state.balance = 0
      state.session = null
      appLocalStorage.clear()
    },
    setPreviewMode({ commit }, value) {
      commit('SET_PREVIEW', value)
    },
    async getUserInfo({ state, commit }, { accessToken }) {
      commit('SET_ACCESS_TOKEN', accessToken)
      appSessionStorage.setWithExpiry(PLAYLINK_STORAGE_KEY.ACCESS_TOKEN, {
        accessToken,
        walletAddress: state.wallet.address
      }, 45 * 60 * 1000)
      const { data: user } = await APIUserIdentity()
      commit('SET_USER', user)
    },
    logoutIAM({ state }) {
      state.wallet = {}
      state.accessToken = ''
      state.user = null
      appSessionStorage.clear()
      appLocalStorage.removeBulkItems(PLAYLINK_STORAGE_KEY)
    }
  },
  modules: {
  }
});
