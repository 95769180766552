import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/style/main.scss'
import PortalVue from 'portal-vue'
import VueI18n from 'vue-i18n'
import messages from '@/i18n'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

Vue.config.productionTip = false
Vue.use(PortalVue)
Vue.directive('click-outside', {
  bind: function (el, binding, vNode) {
    // Provided expression must evaluate to a function.
    if (typeof binding.value !== 'function') {
      const compName = vNode.context.name
      let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`
      if (compName) {
        warn += `Found in component '${compName}'`
      }
      console.warn(warn)
    }
    // Define Handler and cache it on the element
    const bubble = binding.modifiers.bubble
    const handler = (e) => {
      if (bubble || (!el.contains(e.target) && el !== e.target)) {
        binding.value(e)
      }
    }
    el.__vueClickOutside__ = handler
    // add Event Listeners
    document.addEventListener('click', handler)
  },

  unbind: function (el) {
    // Remove Event Listeners
    document.removeEventListener('click', el.__vueClickOutside__)
    el.__vueClickOutside__ = null
  }
})
Vue.filter('fullWebsiteURL', function(website) {
  const regex = /https?:\S+\w/gm
  if (!regex.test(website)) {
    return 'https://' + website
  }
  return website
})
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: 'en',
  messages
})

const fpPromise = FingerprintJS.load({ monitoring: false })
Vue.prototype.$fingerprint = fpPromise

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export {store}
