import {baseURL, PLAYLINK_STORAGE_KEY, iamURL} from "@/common/constants"
import {appSessionStorage} from "@/common/utils/app-storage"
import store from '@/store'
import {withBase} from 'ufo'
import {isJSONSerializable, isPayloadMethod} from '@/common/utils'
import axios from 'axios'

function playlinkFetch(_request, _options = {}) {
  const baseOptions = {
    method: 'get',
    credentials: 'include',
    headers: {}
  }
  const request = withBase(_request, baseURL)
  const options = {
    ...baseOptions,
    ..._options
  }
  if (options.body && isPayloadMethod(options.method) && isJSONSerializable(options.body)) {
    options.body = typeof options.body === 'string' ? options.body : JSON.stringify(options.body)
    options.headers = new Headers(options.headers)
    if (!options.headers.has('content-type')) {
      options.headers.set('content-type', 'application/json')
    }
    if (!options.headers.has('accept')) {
      options.headers.set('accept', 'application/json')
    }
  }
  const uid = appSessionStorage.getWithExpiry(PLAYLINK_STORAGE_KEY.UID)
  if (uid) {
    options.headers['x-uid'] = uid
  }
  return fetch(request, options)
}

function getRequestUrl(endpoint) {
  return (store.getters.previewMode ? '/preview' : '') + endpoint
}

function getService(baseURL) {
  const service = axios.create({
    baseURL,
    timeout: 40000
  })

  service.interceptors.request.use(
    (config) => {
      if (store.getters.accessToken) {
        config.headers['Authorization'] = `Bearer ${store.getters.accessToken}`
      }
      return config
    }
  )

  service.interceptors.response.use(
    (res) => {
      return { data: res.data, status: res.status }
    },
    (error) => {
      const message = (error.response && error.response.data && error.response.data.message)
        ? error.response.data.message : null
      const status = error.response ? error.response.status : 0
      const data = error.response && error.response.data && error.response.data.data

      const responseError = { message, status, data }
      return Promise.reject(responseError)
    }
  )

  return service
}

const iamService = getService(iamURL)


export {
  playlinkFetch,
  getRequestUrl,
  iamService
}
