export const NETWORK_CLUSTER = {
  ETHEREUM: "ethereum",
  RONIN: "ronin",
  SOLANA: "solana",
  SUI: "sui",
  COSMOS: "cosmos",
  KARDIACHAIN: "kardiachain",
  KLAYTN: "klaytn",
  FLOWCHAIN: "flowchain",
  IMMUTABLEX: "immutablex",
  APTOS: "aptos",
  NEAR: "near"
};

export const ALGORITHM = {
  SECP256K1: "secp256k1",
  ED25519: "ed25519",
  SUI_ALGO: "sui",
  FLOWCHAIN_ALGO: "flowchain",
  NEAR_ALGO: "near"
};

export const BASE = {
  BASE16: 16,
  BASE58: 58,
  BASE64: 64
};

export const WALLET_CLASS_MAP = {
  [NETWORK_CLUSTER.ETHEREUM]: [
    {
      fileName: 'metamask',
      className: 'MetaMask'
    },
    {
      fileName: 'coinbase',
      className: 'Coinbase'
    },
    {
      fileName: 'wallet-connect-v2',
      className: 'WalletConnectV2'
    }
  ],
  [NETWORK_CLUSTER.SOLANA]: [
    {
      fileName: 'solana',
      className: ['CoinbaseSolana', 'Glow', 'Phantom', 'Slope', 'Solflare']
    },
  ],
  [NETWORK_CLUSTER.RONIN]: [
    {
      fileName: 'ronin',
      className: 'Ronin',
      testnet: true
    },
    {
      fileName: 'ronin-sso',
      className: 'RoninSSO',
      testnet: false
    }
  ],
  [NETWORK_CLUSTER.SUI]: [
    {
      fileName: 'sui',
      // className: ['SuiWallet', 'SuietWallet']
      className: ['SuiWallet', 'EthosWallet', 'GlassWallet'] // We temporarily disable SuietWallet until they update their signing feature
    },
  ],
  [NETWORK_CLUSTER.IMMUTABLEX]: [
    {
      fileName: 'immutablex-v2',
      className: 'ImmutableXv2'
    },
  ],
  [NETWORK_CLUSTER.FLOWCHAIN]: [
    {
      fileName: 'lilico',
      className: 'Lilico'
    },
  ],
  [NETWORK_CLUSTER.KARDIACHAIN]: [
    {
      fileName: 'kardiachain',
      className: 'KardiaChain'
    },
  ],
  [NETWORK_CLUSTER.KLAYTN]: [
    {
      fileName: 'kaikas',
      className: 'Kaikas'
    },
  ],
  [NETWORK_CLUSTER.COSMOS]: [
    {
      fileName: 'cosmostation',
      className: 'Cosmostation'
    },
    {
      fileName: 'keplr',
      className: 'Keplr'
    }
  ],
  [NETWORK_CLUSTER.APTOS]: [
    {
      fileName: 'aptos',
      className: ['Fewcha', 'Martian', 'Petra', 'RiseWallet']
    },
  ],
  [NETWORK_CLUSTER.NEAR]: [
    {
      fileName: 'nightly',
      className: 'Nightly'
    },
  ],
}
