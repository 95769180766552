<template>
  <body class="theme">
    <div id="app">
      <router-view />
    </div>
    <portal-target
      name="dialog"
      multiple
    />
    <portal-target
      name="dropdown"
      multiple
    />
    <div class="theme-button" @click="changeTheme" ref="btn" data-theme="Experimental dark mode">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 0C8.42758 0 8.77419 0.346618 8.77419 0.774194V2.32258C8.77419 2.75016 8.42758 3.09677 8 3.09677C7.57242 3.09677 7.22581 2.75016 7.22581 2.32258V0.774194C7.22581 0.346618 7.57242 0 8 0Z"/>
        <path d="M8 12.9032C8.42758 12.9032 8.77419 13.2498 8.77419 13.6774V15.2258C8.77419 15.6534 8.42758 16 8 16C7.57242 16 7.22581 15.6534 7.22581 15.2258V13.6774C7.22581 13.2498 7.57242 12.9032 8 12.9032Z"/>
        <path d="M8 12.129C10.2804 12.129 12.129 10.2804 12.129 8C12.129 5.7196 10.2804 3.87097 8 3.87097C5.7196 3.87097 3.87097 5.7196 3.87097 8C3.87097 10.2804 5.7196 12.129 8 12.129ZM8 10.5806C9.42525 10.5806 10.5806 9.42525 10.5806 8C10.5806 6.57475 9.42525 5.41935 8 5.41935C6.57475 5.41935 5.41935 6.57475 5.41935 8C5.41935 9.42525 6.57475 10.5806 8 10.5806Z"/>
        <path d="M15.2258 8.77419C15.6534 8.77419 16 8.42758 16 8C16 7.57242 15.6534 7.22581 15.2258 7.22581H13.6774C13.2498 7.22581 12.9032 7.57242 12.9032 8C12.9032 8.42758 13.2498 8.77419 13.6774 8.77419H15.2258Z"/>
        <path d="M2.32258 8.77419C2.75016 8.77419 3.09677 8.42758 3.09677 8C3.09677 7.57242 2.75016 7.22581 2.32258 7.22581H0.774194C0.346619 7.22581 0 7.57242 0 8C0 8.42758 0.346619 8.77419 0.774193 8.77419H2.32258Z"/>
        <path d="M13.6569 2.34315C13.9592 2.64549 13.9592 3.13568 13.6569 3.43802L12.562 4.5329C12.2597 4.83524 11.7695 4.83524 11.4671 4.5329C11.1648 4.23056 11.1648 3.74036 11.4671 3.43802L12.562 2.34315C12.8643 2.04081 13.3545 2.04081 13.6569 2.34315Z"/>
        <path d="M4.53292 11.4671C4.83526 11.7694 4.83526 12.2596 4.53292 12.562L3.43804 13.6569C3.1357 13.9592 2.64551 13.9592 2.34317 13.6569C2.04083 13.3545 2.04083 12.8643 2.34317 12.562L3.43804 11.4671C3.74038 11.1648 4.23058 11.1648 4.53292 11.4671Z"/>
        <path d="M12.562 13.6569C12.8644 13.9592 13.3545 13.9592 13.6569 13.6569C13.9592 13.3545 13.9592 12.8643 13.6569 12.562L12.562 11.4671C12.2597 11.1648 11.7695 11.1648 11.4671 11.4671C11.1648 11.7695 11.1648 12.2596 11.4671 12.562L12.562 13.6569Z"/>
        <path d="M3.43805 4.5329C3.74039 4.83524 4.23059 4.83524 4.53293 4.5329C4.83527 4.23056 4.83527 3.74037 4.53293 3.43803L3.43805 2.34315C3.13571 2.04081 2.64552 2.04081 2.34318 2.34315C2.04084 2.64549 2.04084 3.13568 2.34318 3.43803L3.43805 4.5329Z"/>
      </svg>
    </div>
  </body>
</template>

<script>
export default {
  methods: {
    changeTheme() {
      const body = document.querySelector('body.theme')
      const el = this.$refs.btn || document.querySelector('.theme-button')
      if (body.classList.contains('dark')) {
        body.classList.remove('dark')
        el.style.background = '#25223C'
        el.style.color = '#D9D9D9'
        el.setAttribute('data-theme', 'Experimental dark mode')
      } else {
        body.classList.add('dark')
        el.style.background = '#FFFFFF'
        el.style.color = '#1d1d1d'
        el.setAttribute('data-theme', 'Switch to light mode')
      }
    }
  }
}
</script>

<style>
.theme-button {
  position: fixed;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #25223C;
  color: #D9D9D9;
  cursor: pointer;

  &:hover:after {
    content: attr(data-theme);
    position: absolute;
    top: 36px;
    right: -8px;
    padding: 2px 4px;
    background: inherit;
    color: inherit;
    font-size: 10px;
    line-height: 12px;
    border-radius: 4px;
    white-space: nowrap;
  }
}

.theme-button svg path {
  fill: currentColor;
}
</style>
