export const ERR_WALLET_NOT_INSTALLED = "Not Installed!";
export const ERR_WALLET_NOT_INITIALIZED = "err_wallet_not_initialized";
export const ERR_BYPASS_REGISTRATION = "Bypass Registration";
export const ERR_GET_CHALLENGE_FAILED = "err_get_challenge";
export const ERR_SIGNATURE_VERIFICATION_FAILED = "err_verify_signature";
export const ERR_TOKEN_GATE_ERROR = "err_check_token_gate";
export const ERR_ASSET_CLAIM_FAILED = "err_claim_assets";
export const ERR_CONNECTION_REJECTED = "err_connection_rejected";
export const ERR_CONNECTION_FAILED = "err_connection_failed";
export const ERR_CHAIN_ADD_REJECTED = "err_chain_add_rejected";
export const ERR_CHAIN_SWITCH_REJECTED = "err_chain_switch_rejected";
export const ERR_SIGNATURE_REJECTED = "err_signature_rejected";
export const ERR_NO_DELEGATE_CASH = "err_no_delegate_cash";
export const ERR_UNKNOWN = "err_unknown";

export const ERROR_CODES = {
  // ethers.js error codes
  ETHERSJS_ACTION_REJECTED: "ACTION_REJECTED",

  // MetaMask error codes
  METAMASK_ACTION_REJECTED: 4001,

  // Kaikas error codes
  KAIKAS_ACTION_REJECTED: -32603
};

export const ACTIONS = {
  CONNECT_WALLET: "CONNECT_WALLET",
  SWITCH_CHAIN: "SWITCH_CHAIN",
  ADD_CHAIN: "ADD_CHAIN",
  SIGN_MESSAGE: "SIGN_MESSAGE",
  QUERY_BALANCES: "QUERY_BALANCES"
};

export const generateError = (errorCode, action) => {
  switch (true) {
    case ((errorCode === ERROR_CODES.ETHERSJS_ACTION_REJECTED ||
      errorCode === ERROR_CODES.METAMASK_ACTION_REJECTED ||
      errorCode === ERROR_CODES.KAIKAS_ACTION_REJECTED) && action === ACTIONS.CONNECT_WALLET):
      return ERR_CONNECTION_REJECTED;
    case ((errorCode === ERROR_CODES.ETHERSJS_ACTION_REJECTED ||
      errorCode === ERROR_CODES.METAMASK_ACTION_REJECTED ||
      errorCode === ERROR_CODES.KAIKAS_ACTION_REJECTED) && action === ACTIONS.SWITCH_CHAIN):
      return ERR_CHAIN_SWITCH_REJECTED;
    case ((errorCode === ERROR_CODES.ETHERSJS_ACTION_REJECTED ||
      errorCode === ERROR_CODES.METAMASK_ACTION_REJECTED ||
      errorCode === ERROR_CODES.KAIKAS_ACTION_REJECTED) && action === ACTIONS.ADD_CHAIN):
      return ERR_CHAIN_ADD_REJECTED;
    case ((errorCode === ERROR_CODES.ETHERSJS_ACTION_REJECTED ||
      errorCode === ERROR_CODES.METAMASK_ACTION_REJECTED ||
      errorCode === ERROR_CODES.KAIKAS_ACTION_REJECTED) && action === ACTIONS.SIGN_MESSAGE):
      return ERR_SIGNATURE_REJECTED;
    default:
      return ERR_UNKNOWN;
  }
}